//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import {miceService} from "../service/miceService";
    import { iScroll } from '@/icomponents';
    export default {
        components: {
            iScroll
        },
        data () {
            return {
                title: '',
                personForm: {
                    name: '',
                    email: ''
                },
                personList: [],
                personNumber: 0, // 已邀请人数
                pageSize: 100,
                pageIndex: 1,
                allLoaded: false,
                topHeight: 0,
                bottomHeight: 0,
                scrollTop: 0,
            }
        },
        mounted() {
            this.getScrollBoxHeight();
        },
        created() {
            this.init()
        },
        computed: {
        },
        methods: {
            init () {
                this.getinvitationPerson()
            },
            eventDetailGoBack () {
                this.iStorage.set('selectItem', '')
                this.$router.push('/')
            },
            saveForm () {
                let regEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                if(!regEmail.test(this.personForm.email)) {
                    this.$iToast("邮箱格式不正确");
                    return false;
                }
                let verifyMap = ["name"]; //必填项key集合
                let verify = verifyMap.some((ele) => {
                    return !this.personForm[ele];
                })
                if(verify) {
                    this.$iToast('人员基本信息不完整');
                    return false;
                }
                this.personForm.mettingId = this.iStorage.get('selectItem').mettingId
                if (this.personForm.invitationId) {
                    this.updatePerson()
                    return false
                }
                miceService.invitationAdd(this.personForm).then(res => {
                    if (res.success) {
                        this.$iToast('人员基本信息添加成功');
                        this.personForm = {name: '', email: ''}
                        this.getinvitationPerson()
                    }
                })
            },
            sendOut () {
                if (!this.personList.length) {
                    this.$iToast('请添加邀请人');
                    return false;
                }
                let params = {
                    MettingId: this.iStorage.get('selectItem').mettingId,
                    invitationIds: []
                }
                this.personList.forEach(item => {
                    params.invitationIds.push(item.invitationId)
                })
                miceService.SendEmailInvitation(params).then(res => {
                    if (res.success) {
                        this.$iMessage.show({
                            title: "提示",
                            contentTxt: `邮件发送成功`,
                            hasLeft: false,
                            closeModal: false,
                            confirmTxt: "我知道了",
                            onConfirm: function() {
                                this.iStorage.set('selectItem', '')
                                this.$router.push('/')
                            }.bind(this)
                        })
                    }
                })
            },
            cancel () {
                this.iStorage.set('showOptions', true)
                this.$router.push('/')
            },
            getinvitationPerson () {
                let params = {
                    MettingId: this.iStorage.get('selectItem').mettingId,
                    pageNo: this.pageIndex,
                    pageSize: this.pageSize,
                    status: 0
                }
                this.allLoaded = false
                miceService.invitationQuery(params).then(res => {
                    if (res.success) {
                        this.allLoaded = true
                        this.$refs.scrollWrapper.scrollListener();
                        this.personList = res.content
                        this.getinvitationPersonNumber()
                    }
                })
            },
            getinvitationPersonNumber () {
                let params = {
                    MettingId: this.iStorage.get('selectItem').mettingId,
                    pageNo: this.pageIndex,
                    pageSize: this.pageSize,
                    status: 1
                }
                miceService.invitationQuery(params).then(res => {
                    if (res.success) {
                        this.personNumber = res.content.length
                    }
                })
            },
            showPersonList () {
                this.$router.push('/invitedPeople')
            },
            updatePerson (item) {
                if (item) {
                    this.personForm = item
                } else {
                    miceService.invitationUpdate(this.personForm).then(res => {
                        if(res.success) {
                            this.$iToast('修改成功');
                            this.getinvitationPerson()
                        }
                    })
                }
            },
            deletePerson (item) {
                let params = {
                    invitationId: item.invitationId
                }
                miceService.invitationDelete(params).then(res => {
                    if(res.success) {
                        this.$iToast('删除成功');
                        this.getinvitationPerson()
                    }
                })
            },
            //上拉下拉
            scrollLoadData() {
                this.getinvitationPerson();
            },
            //监听滚动
            loadmoreScroll(event) {
                this.scrollTop = event.target.scrollTop;
            },
            getScrollBoxHeight() {
                this.$nextTick(() => {
                    const event_header_container = document.getElementsByClassName("event_header_container");
                    const footer_module = document.getElementsByClassName("personInfoFooter");
                    this.topHeight = event_header_container ? event_header_container[0].clientHeight : 0;
                    this.bottomHeight = footer_module[0] ? footer_module[0].clientHeight : 0;
                })
            }
        }
    }
